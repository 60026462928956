/* Global styles */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Add padding to prevent overlap with reCAPTCHA */
  padding-bottom: 100px;
}

/* Typography */
.body-text {
  font-size: 1rem;
  line-height: 1.8;
  color: inherit;
}

/* Responsive typography */
@media (max-width: 600px) {
  .body-text {
    font-size: 0.875rem;
  }
}

/* Custom classes */
.text-gradient {
  background: linear-gradient(45deg, #1a4b8c, #446ca8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  font-size: 1.1rem;
}

/* Animations */
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Move reCAPTCHA badge to not overlap with cookie settings */
.grecaptcha-badge {
  bottom: 100px !important;
  z-index: 999;
}

/* Add space at the bottom of the page */
#kontakt {
  margin-bottom: 80px !important;
}
